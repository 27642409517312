.radio-group_options {
  height: 37px;
  border-radius: 7px;
  border: 1px solid var(--c-light-grey);
  font-size: 14px;
  padding: 5px 7px;
  box-sizing: border-box;

  display: flex;
}
.radio-group_options:hover {
  border-color: var(--c-main-green)
}


.radio-group_label {
  height: 100%;
  border-radius: 5px;
  padding: 0px 7px;
  line-height: 23px;
  
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--c-grey);
  color: white;
  transition: 500ms;

  box-sizing: border-box;
  text-align: right;
}
.radio-group_label:hover {
  cursor: pointer;
}

.radio-group_option {  
  transition: 500ms;
  height: 25px;
  margin: 0px 3px;
  flex-grow: 1;
}
.radio-group_option:first-child {
  margin-left: 0px
}
.radio-group_option:last-child {
  margin-right: 0px
}

.radio-group_option__selected {
  flex-grow: 20;
  transition: 500ms;
}

.radio-group_option input{
  display: none;
}
.radio-group_option input:checked ~ .radio-group_label {
  background-color: var(--c-main-green);
  color: var(--c-grey);
  transition: 500ms;
}

.radio-group_options.radio-group_options__error {
  border-color: var(--c-error-red)
}

/*NEW*/
.radio-group_option-container {
  
  transition: 500ms;
  height: 25px;
  margin: 0px 3px;
  flex-grow: 1;
}




/* TOOLTIP */

.radio-group_tooltip-container {
  position: relative;
  width: 100%;
  height: 0;
}

.radio-group_tooltip {
  opacity: 0;
  display: none; /*can be deleted when tooltips is not in the label tag*/
  transition: opacity 200ms;
  position: absolute;
  top: -31px;
  left: -8px;
  padding: 6px 8px;

  background-color: white;
  box-shadow: 2px 2px 7px 2px #0000003b;

  min-height: 25px;
  border-radius: 5px;
}

.radio-group_tooltip-name {
  height: 25px;
  background-color: var(--c-violet);
  box-sizing: inherit;
}
.radio-group_tooltip-description {

}
/*
.radio-group_option__selected .radio-group_tooltip{
  width: 150%;
  transition: width 500ms;
}
.radio-group_option__selected .radio-group_tooltip-name{
  width: calc(2 / 3 * 100%);  
}
*/
.radio-group_option:hover .radio-group_tooltip {
  opacity: 1;  
  transition: opacity 400ms;
  display: inherit; /*can be deleted when tooltips is not in the label tag*/
}