.checkbox-group_options {
  display: grid;
}
.checkbox-group_options__double {
  height: 91.6px;
}
.checkbox-group_options-row {
  display: flex;
  height: 25px;
  align-self: center;
}
.checkbox-group_options-row:first-child{
  align-self: flex-start;
}
.checkbox-group_options-row:last-child {
  align-self: flex-end;
}