.window {
  border-radius: var(--window-border-radius);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(158, 171, 165, 0.1);
  backdrop-filter: blur(15px);

  box-sizing: border-box;

  padding: 15px 25px 25px 25px;
  width: fit-content;

  display: grid;
  grid-template-rows: max-content auto;

  z-index: 0;
}
.window__blank {
  padding: 0;
  display: block;
}

.window__blank  .window_body {
  height: 100%;
}


.window.window__empty {
  grid-area: auto;
}

.window__empty  .window_body {
  border: 1px solid var(--c-light-grey);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5%
}

.window__empty-text {
  font-size: 20px;
  color: var(--c-light-grey);
  max-width: 70%;
  text-align: center;
}
.window__empty-icon {
  margin-top: 20px;
  width: 50px;
  height: 60px;
}
.window__empty-icon > svg {
  width: 100%;
  height: 100%;
}