.meal-plan-page {
  height: 100%;
  display: grid;
  grid-template-columns: max-content auto;
}
.meal-plan-page_navbar {
  height: 100%;
}
.meal-plan-page_main-area {
  display: flex;
  justify-content: center;
  padding: 8vh 0;

  overflow-y: auto;

}

/*Scrollbar*/
.meal-plan-page_main-area::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.meal-plan-page_main-area::-webkit-scrollbar-track { 
  background : rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.meal-plan-page_main-area::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.2);
  border-radius: 5px;      
}
.meal-plan-page_main-area:hover::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.3); 
}
/**/

.meal-plan-page_container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.meal-plan_header-window {
  margin-bottom: 15px;
  
}
.meal-plan_header {
  display: grid;
  grid-template-columns: auto 53.8px;
  grid-template-rows: 53.8px auto;
  grid-template-areas: 
    "title refresh"
    "body body";
}
.meal-plan_title {
  grid-area: title;
  padding: 15px 0 0 25px;
}
.meal-plan_refresh {
  grid-area: refresh;
  font-size: 28px;
}
.meal-plan_download{
  color: var(--c-dark-green);
  font-weight: 600;
}
.meal-plan_download:hover{
  cursor: pointer;
  color: var(--c-violet)
}

.meal-plan_header-body {
  grid-area: body;
  padding: 0 25px 25px 25px;
}

.meal-plan_select-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 30px;  
  height: 24px;
  line-height: 14px;
}
.meal-plan_prev-button, .meal-plan_next-button{
  color: var(--c-dark-green)
}
.meal-plan_prev-button:hover, .meal-plan_next-button:hover{
  color: var(--c-violet);
  cursor: pointer;
}