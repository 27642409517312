@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap');

:root {
  --c-violet: #A4145A;
  --c-dark-violet: #60374A;
  --c-main-green: #41BE82;
  --c-leaf-green: #65C963;
  --c-dark-green: #4D7B65;
  --c-grey: #424242;
  --c-light-grey: #9EABA5;
  --c-error-red: #CC2525;
  --gradient-main: linear-gradient(180deg, #71D86F 0%, rgba(255, 255, 255, 0) 100%), #3DB27A;
  --window-border-radius: 8px;
}

* {
  font-family: 'Open Sans', sans-serif;
  color: var(--c-grey);
}

*::selection {
  background-color: var(--c-main-green);
  color: white
}
a {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

html, body, #root, .app {
  height: 100%;
  margin: 0
}

h2 {
  font-size: 24px;
  padding: 0px 8px 6px 8px;
  margin: 0;
  font-weight: 400;
}
h3 {
  font-size: 20px;
  padding: 0px 6px 2px 6px;
  margin: 0;
  color: var(--c-dark-green);
  font-weight: 400;
}