.select {
  border-radius: 7px;
  border: 1px solid var(--c-light-grey);
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
}
.select__double{ 
  
  height: 91.6px;
}

.select:hover {
  cursor: pointer;
  border-color: var(--c-main-green)
}

.select_dropdown-container {
  position: relative;
  width: 100%;
  height: 0px;
}

.select_dropdown {  
  z-index: 100;

  box-shadow: 2px 2px 7px 2px #0000003b;
  font-size: 14px;
  background-color: var(--c-grey);  
  position: absolute;
  width: 100%;
  top: -37px;  
  border-radius: 7px;  
  min-height: 37px;
}


.select_dropdown__double{
  top: -91.6px;
  min-height: 91.6px;
}

.select_dropdown .select_option-description{
  display: inherit;
  background: none;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: inherit;
  max-height: inherit;
  height: inherit;
}
.select_dropdown .select_option:hover {
  background: inherit;
  filter: brightness(1.05);
  cursor: pointer;
}
.select_dropdown .select_option {
  border-radius: 7px;
  padding: 7.8px 11px;
  color: white;
}
.select_dropdown .select_option * {
  color: inherit
}
.select_dropdown > .select_option:first-child {
  border-radius: 7px;
  background: var(--c-main-green);
  color: var(--c-grey);
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.1);
}
.select_dropdown > .select_option:first-child *{
  color: inherit
}

.select_option {
  padding: 6.8px 10px;
  box-sizing: border-box;
  max-height: 100%;  
}


.select_option-description {
  color: inherit;
  display: none;
  padding: 0 6px;
  font-size: 12px;
  background: -webkit-linear-gradient(var(--c-grey) 0%, #424242c4 64%, transparent 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 64.8px;
}



.select_option-label {
  max-height: 19.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__double .select_option-description {
  display: inherit;
  color: inherit;
}