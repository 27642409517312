.data-table {
  width: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;

  border-radius: 7px;
  background-color: white;  
  padding: 5px 8px;
  box-sizing: border-box;
}
.data-table__small {
  font-size: 14px;
}
.data-table__large {
  font-size: 18px;
}



.data-table_field {
  width: 100%;
  display: flex;
  padding-bottom: 3px;
}
.data-table_field:last-child {
  padding-bottom: 0;
}


.data-table_field-name {
  font-weight: 300;
}

.data-table_field-leader {
  font-weight: 300;
  flex-grow: 1;
  overflow: hidden;
  margin: 0 5px;
  color: var(--c-light-grey)
}

.data-table_field-leader::before {
  float: left;
  width: 0;
  white-space: nowrap;  
}
.data-table_field-leader__dots::before {
  content:
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
}
.data-table_field-leader__centered-dots::before {
  content:
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
} 

.data-table_field-value {
}
.data-table_field-unit {
  margin-left: 1px;
}
.data-table_field-sign {
  margin-right: 2px;
}