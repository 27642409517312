@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap);
:root {
  --c-violet: #A4145A;
  --c-dark-violet: #60374A;
  --c-main-green: #41BE82;
  --c-leaf-green: #65C963;
  --c-dark-green: #4D7B65;
  --c-grey: #424242;
  --c-light-grey: #9EABA5;
  --c-error-red: #CC2525;
  --gradient-main: linear-gradient(180deg, #71D86F 0%, rgba(255, 255, 255, 0) 100%), #3DB27A;
  --window-border-radius: 8px;
}

* {
  font-family: 'Open Sans', sans-serif;
  color: #424242;
  color: var(--c-grey);
}

*::selection {
  background-color: #41BE82;
  background-color: var(--c-main-green);
  color: white
}
a {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

html, body, #root, .app {
  height: 100%;
  margin: 0
}

h2 {
  font-size: 24px;
  padding: 0px 8px 6px 8px;
  margin: 0;
  font-weight: 400;
}
h3 {
  font-size: 20px;
  padding: 0px 6px 2px 6px;
  margin: 0;
  color: #4D7B65;
  color: var(--c-dark-green);
  font-weight: 400;
}
.meal-plan-page {
  height: 100%;
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
}
.meal-plan-page_navbar {
  height: 100%;
}
.meal-plan-page_main-area {
  display: flex;
  justify-content: center;
  padding: 8vh 0;

  overflow-y: auto;

}

/*Scrollbar*/
.meal-plan-page_main-area::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.meal-plan-page_main-area::-webkit-scrollbar-track { 
  background : rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.meal-plan-page_main-area::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.2);
  border-radius: 5px;      
}
.meal-plan-page_main-area:hover::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.3); 
}
/**/

.meal-plan-page_container {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.meal-plan_header-window {
  margin-bottom: 15px;
  
}
.meal-plan_header {
  display: grid;
  grid-template-columns: auto 53.8px;
  grid-template-rows: 53.8px auto;
  grid-template-areas: 
    "title refresh"
    "body body";
}
.meal-plan_title {
  grid-area: title;
  padding: 15px 0 0 25px;
}
.meal-plan_refresh {
  grid-area: refresh;
  font-size: 28px;
}
.meal-plan_download{
  color: var(--c-dark-green);
  font-weight: 600;
}
.meal-plan_download:hover{
  cursor: pointer;
  color: var(--c-violet)
}

.meal-plan_header-body {
  grid-area: body;
  padding: 0 25px 25px 25px;
}

.meal-plan_select-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 30px;  
  height: 24px;
  line-height: 14px;
}
.meal-plan_prev-button, .meal-plan_next-button{
  color: var(--c-dark-green)
}
.meal-plan_prev-button:hover, .meal-plan_next-button:hover{
  color: var(--c-violet);
  cursor: pointer;
}
.navbar {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: grid;
  position: relative;  
  
}
.navbar__vertical {
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  grid-gap: 15px;
  padding: 10px;
  width: 300px;
  height: 100%;
}



.navbar__vertical .navbar_title {
  padding: 5px 8px;
  font-size: 36px;
}

.navbar__vertical .navbar_divider {
  width: 100%;
  height: 1px;
  background: radial-gradient(var(--c-light-grey) 46%, transparent 72%);
}

.navbar__vertical .navbar_body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  overflow-y: auto;
}

.navbar__vertical .navbar_body::-webkit-scrollbar {
  width: 5px;
}
.navbar__vertical .navbar_body::-webkit-scrollbar-track { 
  background : rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.navbar__vertical .navbar_body::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.2);
  border-radius: 5px;      
}
.navbar__vertical .navbar_body:hover::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.3); 
}


.navbar__vertical .navbar_child:hover  {
  background-color: rgba(0, 0, 0, 0.03)
}

.navbar__vertical .navbar_child {
  min-height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}
.navbar__vertical .navbar_child > * {
  width: 100%;
}
.navbar__vertical .navbar_child:first-child {
  margin-top: 0px;
}
.navbar__vertical .navbar_child:last-child {
  margin-bottom: 0px;
  border: none;
}

.navbar__horizontal {
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-gap: 15px;
  height: 80px;
  width: 100%;
}
.navbar__horizontal .navbar_header {
  display: flex;
  height: 100%;
}
.navbar__horizontal .navbar_title {
  font-size: 24px;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
}
.navbar__horizontal .navbar_divider {
  height: 100%;
  width: 1px;
  background: radial-gradient(var(--c-light-grey) 46%, transparent 72%);
}
.navbar__horizontal .navbar_body {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px 10px 10px;
}
.navbar__horizontal .navbar_children, .navbar__horizontal .navbar_children__end {
  display: flex;
  align-items: center;
}
.navbar__horizontal .navbar_child {
  margin: 0px 15px
}
.navbar_child:hover {
  cursor: pointer;
}
.navbar__horizontal .navbar_child:hover > * {
  color: var(--c-dark-green);
}



.window {
  border-radius: var(--window-border-radius);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(158, 171, 165, 0.1);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);

  box-sizing: border-box;

  padding: 15px 25px 25px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  display: grid;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;

  z-index: 0;
}
.window__blank {
  padding: 0;
  display: block;
}

.window__blank  .window_body {
  height: 100%;
}


.window.window__empty {
  grid-area: auto;
}

.window__empty  .window_body {
  border: 1px solid var(--c-light-grey);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5%
}

.window__empty-text {
  font-size: 20px;
  color: var(--c-light-grey);
  max-width: 70%;
  text-align: center;
}
.window__empty-icon {
  margin-top: 20px;
  width: 50px;
  height: 60px;
}
.window__empty-icon > svg {
  width: 100%;
  height: 100%;
}
.form-button {
  height: 100%;
  width: 70px;
  margin: 0px 0px 0px auto;
}

.form-button_btn {
  height: 100%;
  width: 50px;
  border-radius: 0px var(--window-border-radius) var(--window-border-radius) 0px;
  font-size: 15px;

  outline: none;
  border: none;

  font-weight: 600;
  margin: 0px 0px 0px auto;
  display: block;
  line-height: 22px;

  box-shadow: inset 5px 0px 6px 0px #00000030;
  
  transition: all 500ms;
}

.form-button_btn:hover {
  cursor: pointer;
  width: 70px;

  box-shadow: inset 9px 0px 13px 0px #00000030;

  transition: all 500ms;
}



.form-button_btn__green {
  background-color: var(--c-main-green);
  color: white;
}
.form-button_btn__green:hover {  
  animation: button-animation__green 2500ms 200ms infinite;
  animation-timing-function: ease-in-out  
}

.form-button_btn__violet {
  background-color: var(--c-violet);
  color: white;
}
.form-button_btn__green:hover {  
  animation: button-animation__violet 2500ms 200ms infinite;
  animation-timing-function: ease-in-out  
}

.form-button_btn:disabled:hover {
  transform: none;
  cursor: default;
}




/*animation*/
@keyframes button-animation__green {
  25% {background-color: var(--c-violet); color: white}
  75% {background-color: var(--c-main-green);}
}
@keyframes button-animation__violet {
  25% {background-color: var(--c-main-green); color: var(--c-grey)}
  75% {background-color: var(--c-violet);}
}


/*corner button*/
.corner-button {
  height: 100%;
  width: 100%;
}

.corner-button_btn {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
  font-size: 18px;
  padding: 0px 15px;

}
.corner-button_btn:hover {
  cursor: pointer;
}
.corner-button_btn__green:hover {
  animation: button-animation__green 2s 100ms infinite;
  animation-timing-function: ease-in-out  
}
.corner-button_btn__violet:hover {
  animation: button-animation__violet 2s 100ms infinite;
  animation-timing-function: ease-in-out  
}

.corner-button_btn__green {
  background-color: var(--c-main-green);
  text-shadow: 0 0 1px #4242426b;
}


.corner-button_btn__violet {
  background-color: var(--c-violet);
  color: white
}


.corner-button_btn__bottom-left {
  box-shadow: inset -5px 5px 6px 0px #00000030;
  border-radius:  0px var(--window-border-radius) 0px var(--window-border-radius);
}
.corner-button_btn__top-right {
  box-shadow: inset 5px -5px 6px 0px #00000030;
  border-radius:  0px var(--window-border-radius) 0px var(--window-border-radius);  
  text-align: right;
}
.corner-button_btn__bottom-right {
  box-shadow: inset 5px 5px 6px 0px #00000030;
  border-radius:  var(--window-border-radius) 0px var(--window-border-radius) 0px;
  text-align: right;
}

.corner-button_btn__top-left {
  box-shadow: inset -5px -5px 6px 0px #00000030;
  border-radius:  var(--window-border-radius) 0px var(--window-border-radius) 0px;
}
.table {
  width: 100%;
  background-color: white;
  border-radius: 7px;
  padding: 5px 8px;
  box-sizing: border-box;

  
}

.table-tag {
  width: 100%;
  border-collapse: collapse;
}

.table_thead {
  border-bottom: 1px solid var(--c-light-grey);
}
.table_thead th {
  padding-bottom: 5px;
}

.table_tbody td {
  padding: 3px
}

.table_td__bold {
  font-weight: 600;
}
.meal-plan_meal-table {
}
.data-table {
  width: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;

  border-radius: 7px;
  background-color: white;  
  padding: 5px 8px;
  box-sizing: border-box;
}
.data-table__small {
  font-size: 14px;
}
.data-table__large {
  font-size: 18px;
}



.data-table_field {
  width: 100%;
  display: flex;
  padding-bottom: 3px;
}
.data-table_field:last-child {
  padding-bottom: 0;
}


.data-table_field-name {
  font-weight: 300;
}

.data-table_field-leader {
  font-weight: 300;
  flex-grow: 1;
  overflow: hidden;
  margin: 0 5px;
  color: var(--c-light-grey)
}

.data-table_field-leader::before {
  float: left;
  width: 0;
  white-space: nowrap;  
}
.data-table_field-leader__dots::before {
  content:
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
  ". . . . . . . . . . . . . . . . . . . . "
}
.data-table_field-leader__centered-dots::before {
  content:
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
  "· · · · · · · · · · · · · · · · · · · · "
} 

.data-table_field-value {
}
.data-table_field-unit {
  margin-left: 1px;
}
.data-table_field-sign {
  margin-right: 2px;
}
.models-page {
  height: 100%;
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
}
.models-page_navbar {
  height: 100%;
}
.models-page_main-area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8vh;
}

.model {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  grid-template-rows: -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content;
  grid-gap: 25px;
  gap: 25px;
}
.model-info-window{
  grid-row: 1/3
}
.input {
  width: 100%;
}
.input_label-tag {
  display: grid;
  grid-template-rows: auto auto;
}
.input_label {
  font-size: 13px;
  padding-left: 2px;
  cursor: default;
}
.input_label__error {
 color: var(--c-error-red)
}

.input_input-tag {
  height: 37px;
  width: 100%;

  border-radius: 7px;
  border: 1px solid var(--c-light-grey);
  outline: none;

  padding: 5px 10px;

  font-size: 14px;

  box-sizing: border-box;
}

.input_input-tag::-webkit-input-placeholder {
  color: var(--c-light-grey)
}

.input_input-tag:-ms-input-placeholder {
  color: var(--c-light-grey)
}

.input_input-tag::-ms-input-placeholder {
  color: var(--c-light-grey)
}

.input_input-tag::placeholder {
  color: var(--c-light-grey)
}

.input_input-tag:hover {
  border-color: var(--c-main-green)
}
.input_input-tag:focus-within {
  border-color: var(--c-main-green)
}

.input_input-tag__error {
  border-color: var(--c-error-red)
}

.input_validity-errors {

  list-style: url(/static/media/leaf-ident.4af8474b.svg) inside;
  padding-left: 15px;
  margin: 0px;

}
.input_validity-error{
  color: var(--c-error-red);
  font-size: 13px;
  font-weight: 600;
}
.radio-group_options {
  height: 37px;
  border-radius: 7px;
  border: 1px solid var(--c-light-grey);
  font-size: 14px;
  padding: 5px 7px;
  box-sizing: border-box;

  display: flex;
}
.radio-group_options:hover {
  border-color: var(--c-main-green)
}


.radio-group_label {
  height: 100%;
  border-radius: 5px;
  padding: 0px 7px;
  line-height: 23px;
  
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--c-grey);
  color: white;
  transition: 500ms;

  box-sizing: border-box;
  text-align: right;
}
.radio-group_label:hover {
  cursor: pointer;
}

.radio-group_option {  
  transition: 500ms;
  height: 25px;
  margin: 0px 3px;
  flex-grow: 1;
}
.radio-group_option:first-child {
  margin-left: 0px
}
.radio-group_option:last-child {
  margin-right: 0px
}

.radio-group_option__selected {
  flex-grow: 20;
  transition: 500ms;
}

.radio-group_option input{
  display: none;
}
.radio-group_option input:checked ~ .radio-group_label {
  background-color: var(--c-main-green);
  color: var(--c-grey);
  transition: 500ms;
}

.radio-group_options.radio-group_options__error {
  border-color: var(--c-error-red)
}

/*NEW*/
.radio-group_option-container {
  
  transition: 500ms;
  height: 25px;
  margin: 0px 3px;
  flex-grow: 1;
}




/* TOOLTIP */

.radio-group_tooltip-container {
  position: relative;
  width: 100%;
  height: 0;
}

.radio-group_tooltip {
  opacity: 0;
  display: none; /*can be deleted when tooltips is not in the label tag*/
  transition: opacity 200ms;
  position: absolute;
  top: -31px;
  left: -8px;
  padding: 6px 8px;

  background-color: white;
  box-shadow: 2px 2px 7px 2px #0000003b;

  min-height: 25px;
  border-radius: 5px;
}

.radio-group_tooltip-name {
  height: 25px;
  background-color: var(--c-violet);
  box-sizing: inherit;
}
.radio-group_tooltip-description {

}
/*
.radio-group_option__selected .radio-group_tooltip{
  width: 150%;
  transition: width 500ms;
}
.radio-group_option__selected .radio-group_tooltip-name{
  width: calc(2 / 3 * 100%);  
}
*/
.radio-group_option:hover .radio-group_tooltip {
  opacity: 1;  
  transition: opacity 400ms;
  display: inherit; /*can be deleted when tooltips is not in the label tag*/
}
.select {
  border-radius: 7px;
  border: 1px solid var(--c-light-grey);
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
}
.select__double{ 
  
  height: 91.6px;
}

.select:hover {
  cursor: pointer;
  border-color: var(--c-main-green)
}

.select_dropdown-container {
  position: relative;
  width: 100%;
  height: 0px;
}

.select_dropdown {  
  z-index: 100;

  box-shadow: 2px 2px 7px 2px #0000003b;
  font-size: 14px;
  background-color: var(--c-grey);  
  position: absolute;
  width: 100%;
  top: -37px;  
  border-radius: 7px;  
  min-height: 37px;
}


.select_dropdown__double{
  top: -91.6px;
  min-height: 91.6px;
}

.select_dropdown .select_option-description{
  display: inherit;
  background: none;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: inherit;
  max-height: inherit;
  height: inherit;
}
.select_dropdown .select_option:hover {
  background: inherit;
  -webkit-filter: brightness(1.05);
          filter: brightness(1.05);
  cursor: pointer;
}
.select_dropdown .select_option {
  border-radius: 7px;
  padding: 7.8px 11px;
  color: white;
}
.select_dropdown .select_option * {
  color: inherit
}
.select_dropdown > .select_option:first-child {
  border-radius: 7px;
  background: var(--c-main-green);
  color: var(--c-grey);
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.1);
}
.select_dropdown > .select_option:first-child *{
  color: inherit
}

.select_option {
  padding: 6.8px 10px;
  box-sizing: border-box;
  max-height: 100%;  
}


.select_option-description {
  color: inherit;
  display: none;
  padding: 0 6px;
  font-size: 12px;
  background: -webkit-linear-gradient(var(--c-grey) 0%, #424242c4 64%, transparent 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 64.8px;
}



.select_option-label {
  max-height: 19.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__double .select_option-description {
  display: inherit;
  color: inherit;
}
.slider_container {
  height: 91.6px;
  box-sizing: border-box;
  border-radius: 7px;
  /*border: 1px solid var(--c-light-grey)*/
}
.slider_container:hover > .slider_info {
  border-color: var(--c-main-green)
}

.slider {
  height: 37px;
  box-sizing: border-box;
}


.slider > input {
  -webkit-appearance: none;  
  -moz-appearance: none;  
       appearance: none;
  box-sizing: border-box;

  overflow: hidden;

  margin: 0;
  width: 100%;
  height: 100%; 
  border-radius: 7px 7px 0 0;
  box-shadow: inset 0 -5px 6px 0px #00000030;
  background: var(--c-main-green);
  outline: none; 
  transition: .2s;

  padding: 0 8px;
}

.slider > input:hover {
  border-color: var(--c-main-green  );
  transition: .2s;
}



.slider > input::-webkit-slider-thumb{
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 7px; 
  height: 37px; 
  background: var(--c-grey);  
  box-shadow: 0 0 10px 3px #00000030;
  cursor: pointer; 
}

.slider > input::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: 7px;
  height: 37px;
  background: var(--c-grey);
  box-shadow: 0 0 10px 3px #00000030;
  cursor: pointer;
  border: none;
}


.slider_info {
  box-sizing: border-box ;
  height: 54.6px;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid var(--c-light-grey);
  padding: 6.8px 10px;

  font-size: 14px;
}

.slider_info-detailed {
  padding: 0 6px;
  font-size: 12px;
  max-height: 16px;
  overflow: hidden;
}
.checkbox-group_options {
  display: grid;
}
.checkbox-group_options__double {
  height: 91.6px;
}
.checkbox-group_options-row {
  display: flex;
  height: 25px;
  align-self: center;
}
.checkbox-group_options-row:first-child{
  align-self: flex-start;
}
.checkbox-group_options-row:last-child {
  align-self: flex-end;
}
.form {
  display: grid;
  grid-template-columns: auto 70px;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  height: 100%;
  /*
  border-radius: var(--window-border-radius);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.20);

  background-color: rgba(158, 171, 165, 0.1);
  backdrop-filter: blur(15px);*/
}

.form_body {
  padding: 15px 15px 25px 25px;
}

.form_fields {
  display: grid;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 3px;
  row-gap: 3px;
  
}

.form_validity-errors {
  list-style: url(/static/media/leaf-ident.4af8474b.svg) inside;
  padding: 15px 0px 0px 15px; 
  margin: 0px;

}
.form_validity-error{
  color: var(--c-error-red);
  font-size: 14px;
  font-weight: 600;
}

.form_section-delimeter {
  width: 100%;
  height: 15px;
}
.result-section {
  display: grid;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  grid-gap: 15px;
  gap: 15px;
  height: 100%;
}
.result-section__row {
  grid-template-rows: auto;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
}

.result-section_sources {
  min-width: 130px;
}

.result-section_source {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 14px;
  padding: 0px 8px;
}


.result-section_source-value {
  text-align: right;
  
  color: var(--c-dark-green)
}

/**/
.result-section_results {

}


.data-square {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  padding: 2px 5px 5px 5px;
  border: 1px solid var(--c-light-grey);

  display: grid;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  box-sizing: border-box;
}
.data-square:hover {
  border-color: var(--c-main-green)
}
.data-square_title {
  text-align: right;
  font-size: 13px;
}
.data-square_fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 20px 8px;
}

.data-square_field {
  color: var(--c-dark-green);
  font-size: 18px;
}

.model-info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.model-info-section_plot {
  width:425px;
  height: 390px;
}

.model-link {
  padding: 6px 10px;
}
.model-link_name {
  font-size: 16px;
  color: var(--c-dark-green);
}
.model-link_description {
  font-size: 14px;
  color: var(--c-light-grey);
  padding-left: 6px
}
.model-link__active {
  background-color: rgb(214, 233, 222);
}
.model-link__active .model-link_name {
  
}
.auth-modal_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  
 /* backdrop-filter: blur(20px);*/
  z-index: 1000;
}


.auth-modal > * {
  background-color: white;
}

.auth-modal_link {
  color: var(--c-dark-green);
  font-weight: 600;
}
.auth-modal_link:hover {
  cursor: pointer;
  color: var(--c-violet)
}


.app_main {
  height: calc(100% - 70px) ;
  position: relative;
}
.preferences-page {
  height: 100%;
}
.preferences-page_main-area {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

}

.preferences-page_grid {
  display: grid;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto;
  grid-template-areas: 
    "physParams nutrients"
    "physParams nutrients"
    "foodPrefs nutrients";
}
.preferences-page_phys-params {
  grid-area: physParams;
}
.preferences-page_phys-activity {
  grid-area: physActivity;
}
.preferences-page_food-prefs {
  grid-area: foodPrefs;
}

/*nutrients*/
.nutrients {
  grid-area: nutrients;
  
}
.nutrients_grid{
  display: grid;
  height: 100%;
  grid-gap: 0 10px;
  gap: 0 10px;
  grid-template-columns: auto 185px 210px;
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content auto 50px;
  grid-template-rows: max-content max-content max-content auto 50px;
  grid-template-areas: 
    "nutrientsTitle nutrientsTitle nutrientsTitle"
    "nutrientsEnergy mainNutrients mainNutrients"
    "nutrientsEnergy otherTitle otherTitle"
    "nutrientsEnergy otherNutrients1 otherNutrients2"
    "nutrientsEnergy otherNutrients1 nutrientsButton";
}

.nutrients_title {
  grid-area: nutrientsTitle;
  padding: 15px 25px 0 25px;
}
.nutrients_energy {
  grid-area: nutrientsEnergy;
  padding: 0 15px 25px 25px;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-rows: 1fr 1fr 1fr;
}
.nutrients_main-nutrients {
  grid-area: mainNutrients;
  padding-right: 25px;
}
.nutrients_other-title {
  grid-area: otherTitle;
  padding-right: 25px;
}
.nutrients_other-nutrients-1 {
  grid-area: otherNutrients1;
  display: flex;
  padding-bottom: 25px;
}
.nutrients_other-nutrients-2 {
  grid-area: otherNutrients2;
  padding: 0 25px 10px 0;
  display: flex;
}
.nutrients_button {
  grid-area: nutrientsButton
}


.stale-code-warning__wrapper {
  box-sizing: border-box;

  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;

  
  padding: 1rem;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

@media (min-width: 1300px) {
  .stale-code-warning__wrapper {
    display: none;
  }
}
.stale-code-warning__wrapper_hidden {
  display: none;
}

.stale-code-warning {
  box-sizing: border-box;

  background: rgb(255, 177, 43);
  box-shadow: rgb(0 0 0 / 0.3) 1px 2px 6px;
  
  padding: 1rem 2rem;
  border-radius: 8px;
  max-width: 70%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  box-sizing: content-box;  
}

.stale-code-warning__button {
  padding: 0;
  margin-top: 0.5rem;
  border: none;
  background: none;
  font-size: inherit;
  cursor: pointer;
  font-weight: 600;
}


