.stale-code-warning__wrapper {
  box-sizing: border-box;

  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;

  
  padding: 1rem;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

@media (min-width: 1300px) {
  .stale-code-warning__wrapper {
    display: none;
  }
}
.stale-code-warning__wrapper_hidden {
  display: none;
}

.stale-code-warning {
  box-sizing: border-box;

  background: rgb(255, 177, 43);
  box-shadow: rgb(0 0 0 / 0.3) 1px 2px 6px;
  
  padding: 1rem 2rem;
  border-radius: 8px;
  max-width: 70%;
  width: max-content;

  box-sizing: content-box;  
}

.stale-code-warning__button {
  padding: 0;
  margin-top: 0.5rem;
  border: none;
  background: none;
  font-size: inherit;
  cursor: pointer;
  font-weight: 600;
}

