.navbar {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: grid;
  position: relative;  
  
}
.navbar__vertical {
  grid-template-rows: max-content auto;
  grid-gap: 15px;
  padding: 10px;
  width: 300px;
  height: 100%;
}



.navbar__vertical .navbar_title {
  padding: 5px 8px;
  font-size: 36px;
}

.navbar__vertical .navbar_divider {
  width: 100%;
  height: 1px;
  background: radial-gradient(var(--c-light-grey) 46%, transparent 72%);
}

.navbar__vertical .navbar_body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  overflow-y: auto;
}

.navbar__vertical .navbar_body::-webkit-scrollbar {
  width: 5px;
}
.navbar__vertical .navbar_body::-webkit-scrollbar-track { 
  background : rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.navbar__vertical .navbar_body::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.2);
  border-radius: 5px;      
}
.navbar__vertical .navbar_body:hover::-webkit-scrollbar-thumb { 
  background-color: rgba(137, 139, 139, 0.3); 
}


.navbar__vertical .navbar_child:hover  {
  background-color: rgba(0, 0, 0, 0.03)
}

.navbar__vertical .navbar_child {
  min-height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}
.navbar__vertical .navbar_child > * {
  width: 100%;
}
.navbar__vertical .navbar_child:first-child {
  margin-top: 0px;
}
.navbar__vertical .navbar_child:last-child {
  margin-bottom: 0px;
  border: none;
}

.navbar__horizontal {
  grid-template-columns: max-content auto;
  grid-gap: 15px;
  height: 80px;
  width: 100%;
}
.navbar__horizontal .navbar_header {
  display: flex;
  height: 100%;
}
.navbar__horizontal .navbar_title {
  font-size: 24px;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
}
.navbar__horizontal .navbar_divider {
  height: 100%;
  width: 1px;
  background: radial-gradient(var(--c-light-grey) 46%, transparent 72%);
}
.navbar__horizontal .navbar_body {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px 10px 10px;
}
.navbar__horizontal .navbar_children, .navbar__horizontal .navbar_children__end {
  display: flex;
  align-items: center;
}
.navbar__horizontal .navbar_child {
  margin: 0px 15px
}
.navbar_child:hover {
  cursor: pointer;
}
.navbar__horizontal .navbar_child:hover > * {
  color: var(--c-dark-green);
}


